import { Box, Flex, HStack, Heading, Text, VStack, VisuallyHidden, colors } from "@biblioteksentralen/react";
import { getPath } from "../../../utils/getPath";
import { useClientSideReady } from "../../../utils/hooks/useClientSideReady";
import { useScreenIsSmall } from "../../../utils/hooks/useScreenIsSmall";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLinkOverlay from "../../InternalLinkOverlay";
import { SanityImagePreservingAspectRatio } from "../../images/SanityImagePreservingAspectRatio";
import { areTimeFragmentsUpToIndexZero, useCountDown } from "../useCountDown";
import { parseBibliobattlesDates } from "./parseBibliobattlesDates";
import { ResolvedBibliobattlesBannerData } from "./sanityQuery";

const translateCharactersUpwards = { base: "0", md: "-0.5rem", lg: "-0.75rem" };

type BannerContentPreludeProps = {
  bibliobattles: ResolvedBibliobattlesBannerData;
  smallScreen: boolean;
};

export const BannerContentPrelude = ({ bibliobattles }: BannerContentPreludeProps) => {
  const { t } = useTranslation();
  const clientSideReady = useClientSideReady();
  const { summaryOfDates } = parseBibliobattlesDates(bibliobattles);
  const smallScreen = useScreenIsSmall();

  const countdownTo = bibliobattles?.startDate
    ? new Date(`${new Date().getFullYear()}-${bibliobattles?.startDate}T00:00:00`)
    : undefined;

  const timeFragments = useCountDown(countdownTo, { smallScreen });

  if (timeFragments.length > 4) console.error("Bibliobattles countdown is not constructed for >= 1 month");
  if (!bibliobattles?.startDate) return null;

  const reversedFragments = timeFragments.slice(0, 4).reverse();

  return (
    <HStack
      zIndex={1}
      justifyContent="space-around"
      width="100%"
      flexWrap="wrap"
      padding={{ base: "2rem 0 3rem", sm: "2rem 0.5rem", md: "2rem 1.2rem", lg: "2rem" }}
      whiteSpace="nowrap"
    >
      <VStack spacing={{ base: "1rem", lg: "1.5rem" }}>
        <VStack spacing="0rem">
          <Text fontSize={{ base: "lg", sm: "1.5rem", lg: "2rem" }}>{t("Bli med på")}</Text>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Heading as="h2" fontSize={{ base: "3xl", sm: "2rem", lg: "2.75rem" }} textShadow="0px 4px 10px #247110">
            Bibliobattles
          </Heading>
          {!!summaryOfDates && <Text fontSize={{ base: "lg", sm: "1.5rem", lg: "2rem" }}>{summaryOfDates}</Text>}
        </VStack>

        {/* TODO: Expose as a link style variant */}
        <InternalLinkOverlay
          href={getPath(bibliobattles)}
          fontSize={{ base: "md", sm: "lg", lg: "xl" }}
          fontWeight="semibold"
          background={colors.black}
          color={colors.white}
          _hover={{ backgroundColor: "hsla(0deg, 0%, 30%, 1)" }}
          transition="all 0.25s ease"
          borderRadius="lg"
          padding={{ base: "0.375rem 0.875rem", sm: "0.5rem 1rem" }}
          boxShadow="0px 3px 20px 0px rgba(36, 113, 16, 0.68)"
          zIndex={2}
        >
          {t("Les mer")}
        </InternalLinkOverlay>

        <Box
          position="absolute"
          bottom="-1.9rem"
          left="50%"
          transform={{ base: "translateX(calc(-50% - 5rem))", sm: "translateX(calc(-50% - 10rem))" }}
          aria-hidden
          display={{ base: "block", md: "none" }}
          width={{ base: "150px", sm: "200px" }}
          _groupHover={{ transform: { scale: "1.2" } }} /* FIXME: Doesn't work */
        >
          <SanityImagePreservingAspectRatio
            image={bibliobattles.templateContent?.bannerCharacters}
            resolution={230}
            boxShadow="none"
          />
        </Box>
      </VStack>

      <VStack
        alignItems="flex-start"
        marginBottom={translateCharactersUpwards}
        spacing={{ base: "0.25rem", lg: "0.5rem" }}
        marginTop="0.5rem"
        display={{ base: "none", md: "block" }}
      >
        <Text fontSize="sm" fontWeight="600">
          {t("Byggingen starter om")}
          {"..."}
        </Text>
        <VisuallyHidden aria-live="polite" aria-atomic aria-relevant="all">
          {reversedFragments
            .slice(0, 3)
            .map(([amount, unit]) => `${amount} ${unit}`)
            .join(", ")}
        </VisuallyHidden>
        {clientSideReady && (
          <Box
            height={{ base: "4rem", lg: "5rem" }}
            width={{ base: "20rem", lg: "24rem" }}
            position="relative"
            aria-hidden
          >
            <Box
              backgroundImage="/minecraftBoard.png"
              backgroundRepeat="repeat-x"
              backgroundSize="contain"
              width="100%"
              height="100%"
            />
            <Flex
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              justifyContent="space-around"
              alignItems="center"
              textShadow="0px 4px 4px #FFE560"
              padding="1.5rem 0.5rem"
            >
              {reversedFragments.map(([amount, unit], idx) => {
                if (areTimeFragmentsUpToIndexZero(reversedFragments, idx)) return null;

                return (
                  <VStack key={idx} spacing="0" fontWeight="semibold" minWidth={{ base: "3.75rem", lg: "4.75rem" }}>
                    <Text fontSize={{ base: "1.75rem", lg: "2.5rem" }} lineHeight="1">
                      {amount}
                    </Text>
                    <Text>{unit}</Text>
                  </VStack>
                );
              })}
            </Flex>
          </Box>
        )}
        <Box
          margin="0 auto"
          position="relative"
          top={translateCharactersUpwards}
          aria-hidden
          width="230px"
          _groupHover={{ transform: { scale: "1.2" } }} /* FIXME: Doesn't work */
        >
          <SanityImagePreservingAspectRatio
            image={bibliobattles.templateContent?.bannerCharacters}
            resolution={230}
            boxShadow="none"
          />
        </Box>
      </VStack>
    </HStack>
  );
};
