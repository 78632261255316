import { Box, HStack, Heading, Text, VStack, colors } from "@biblioteksentralen/react";
import { getPath } from "../../../utils/getPath";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLinkOverlay from "../../InternalLinkOverlay";
import { SanityImagePreservingAspectRatio } from "../../images/SanityImagePreservingAspectRatio";
import { parseBibliobattlesDates } from "./parseBibliobattlesDates";
import { ResolvedBibliobattlesBannerData } from "./sanityQuery";

type BannerContentUnderwayProps = {
  bibliobattles: ResolvedBibliobattlesBannerData;
  smallScreen: boolean;
};

export const BannerContentUnderway = ({ bibliobattles }: BannerContentUnderwayProps) => {
  const { t } = useTranslation();
  const { summaryOfDates } = parseBibliobattlesDates(bibliobattles);

  if (!bibliobattles?.startDate) return null;

  return (
    <HStack
      zIndex={2}
      justifyContent="center"
      spacing="2rem"
      width="100%"
      padding={{ base: "2rem 0 3rem", sm: "2rem 0.5rem", lg: "2rem" }}
      flexWrap="wrap"
      whiteSpace="nowrap"
    >
      <VStack spacing={{ base: "1rem", lg: "1.5rem" }}>
        <VStack spacing="0rem">
          {!!summaryOfDates && <Text fontSize={{ base: "lg", sm: "1.5rem", lg: "2rem" }}>{summaryOfDates}</Text>}
          <Heading
            as="h2"
            fontSize={{ base: "3xl", sm: "2rem", lg: "2.75rem" }}
            textShadow="0px 4px 10px #247110"
            marginBottom={{ base: "0", sm: "0.5rem" }}
            // eslint-disable-next-line i18next/no-literal-string
          >
            Bibliobattles
          </Heading>
        </VStack>
        {/* TODO: Expose as a link style variant */}
        <InternalLinkOverlay
          href={getPath(bibliobattles)}
          fontSize={{ base: "md", sm: "lg", lg: "xl" }}
          fontWeight="semibold"
          background={colors.black}
          color={colors.white}
          _hover={{ backgroundColor: "hsla(0deg, 0%, 30%, 1)" }}
          transition="all 0.25s ease"
          borderRadius="lg"
          padding={{ base: "0.375rem 0.875rem", sm: "0.5rem 1rem" }}
          boxShadow="0px 3px 20px 0px rgba(36, 113, 16, 0.68)"
          zIndex={2}
        >
          {t("Bli med")}
        </InternalLinkOverlay>
      </VStack>
      <Box
        bottom={{ base: "-1.9rem", md: undefined }}
        position={{ base: "absolute", md: "static" }}
        left={{ base: "50%", md: undefined }}
        transform={{ base: "translateX(calc(-50% - 5rem))", sm: "translateX(calc(-50% - 10rem))", md: "none" }}
        aria-hidden
        _groupHover={{ transform: { scale: "1.2" } }} /* FIXME: Doesn't work */
      >
        <Box width={{ base: "150px", sm: "200px", md: "320px" }}>
          <SanityImagePreservingAspectRatio
            image={bibliobattles.templateContent?.bannerCharacters}
            resolution={320}
            boxShadow="none"
          />
        </Box>
      </Box>
    </HStack>
  );
};
