import { NORWEGIAN_TIMEZONE } from "@libry-content/common";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { ResolvedBibliobattlesBannerData } from "./sanityQuery";
import differenceInDays from "date-fns/differenceInDays";
import subDays from "date-fns/subDays";
import { dateFnsLocaleMap } from "@libry-content/localization";

const isValidDate = (date: Date | undefined) => date instanceof Date && !isNaN(date.getTime());

const onError = (errorMessage: string) => {
  console.error(errorMessage);
  return {};
};

const nb = dateFnsLocaleMap.nb;

export const parseBibliobattlesDates = (
  { startDate, endDate }: ResolvedBibliobattlesBannerData,
  { summaryOfDatesSeparator, includeLastDay } = { summaryOfDatesSeparator: "", includeLastDay: true }
): { summaryOfDates?: string; nDays?: number; formattedStart?: string; formattedEnd?: string } => {
  const thisYear = new Date().getFullYear();

  if (!startDate || !endDate) return onError("Missing bibliobattles start or end date");

  const start = new Date(`${thisYear}-${startDate}`);
  const end = subDays(new Date(`${thisYear}-${endDate}`), includeLastDay ? 0 : 1);

  if (!isValidDate(start) || !isValidDate(end)) return onError("Invalid bibliobattles start or end date");
  if (start.getMonth() !== end.getMonth()) return onError("Bibliobattles not implemented for crossing months");

  const month = formatInTimeZone(start, NORWEGIAN_TIMEZONE, "MMMM", { locale: nb });
  const nDays = differenceInDays(end, start);
  const formattedStart = formatInTimeZone(start, NORWEGIAN_TIMEZONE, "d. MMMM", { locale: nb });
  const formattedEnd = formatInTimeZone(end, NORWEGIAN_TIMEZONE, "d. MMMM", { locale: nb });

  const summaryOfDates = [`${start.getDate()}.`, "-", `${end.getDate()}.`, month].join(summaryOfDatesSeparator);

  return { summaryOfDates, nDays, formattedStart, formattedEnd };
};
